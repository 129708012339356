<template>
  <div class="category">
    <div class="list">
      <template v-for="(i, index) in categorys">
        <div class="item flex" :key="index" @click="handleClick(i)">
          <div class="flex">
            <div class="imgs">
              <img :src="i.img" alt="" />
            </div>
            <div class="label">{{ i.label }}</div>
          </div>
          <div><a-icon type="right" /></div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      categorys: [
        {
          img: require('../images/resgiter.png'),
          label: '收文登记',
          auth_list: [...this.supers, ...this.commissioners],
          route: {
            name: 'mobileBillRegister',
          },
        },
        {
          img: require('../images/approval.png'),
          label: '公文审批',
          auth_list: [...this.supers, ...this.commons],
          route: {
            name: 'mobileDocumentApproval',
          },
        },
        {
          img: require('../images/send.png'),
          label: '公文发送',
          auth_list: [...this.supers, ...this.commissioners],
          route: {
            name: 'mobileBillSend',
          },
        },
        {
          img: require('../images/tag.png'),
          label: '标签管理',
          auth_list: [...this.supers],
          route: {
            name: 'mobileLabelManage',
          },
        },
      ],
    }
  },
  props: {
    supers: {
      type: Array,
      default: [],
    },
    commissioners: {
      type: Array,
      default: [],
    },
    commons: {
      type: Array,
      default: [],
    },
    auth_code: {
      type: String,
      default: '',
    },
  },
  computed: {},
  methods: {
    handleClick({ route }) {
      this.$router.push(route)
    },
  },
}
</script>
<style lang="less" scoped>
.category {
  background: #fff;
  .flex {
    display: flex;
    align-items: center;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .list {
    padding: 0 16px;
    .item {
      padding: 16px 0;
      justify-content: space-between;
      border-bottom: 1px solid rgba(246, 246, 246, 1);
      .label {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.8;
      }
      .imgs {
        width: 23px;
        height: 23px;
        font-size: 0;
        margin-right: 12px;
      }
    }
  }
}
</style>
