<template>
  <div class="mobilePerson">
    <div class="header">
      <div class="headerInfo flex">
        <div class="userBaseInfo flex">
          <div class="userInfo flex">
            <div class="avat">
              <div class="imgs"></div>
            </div>
            <div class="user">
              <div class="userName">
                <OpenData type="userName" :openid="userId" :corpid="corpId"></OpenData>
              </div>
              <div class="part">
                <!-- <OpenData type="departmentName" :openid="item.id" :corpid="item.corpId"></OpenData> -->
                <OpenData type="departmentName" :openid="'1'" :corpid="corpId"></OpenData>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="userAccount flex">
        <div class="accountList flex">
          <div class="accountItem flex" @click="toTranslate(i)" v-for="(i, index) in account" :key="index">
            <div class="value">{{ formatNum(i.num) }}</div>
            <div class="label">{{ i.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <category :supers="supers" :commissioners="commissioners" :commons="commons" :auth_code="auth_code" />
  </div>
</template>
<script>
import { statisticsNumber } from '@/api/modular/fileStream/documentManage'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { corpId, userId } from '@/views/fileStream/documentManage/weixin/selectPerson'
import { mapGetters } from 'vuex'
import { initWx } from '../components/minxin'
import category from './components/category.vue'
export default {
  components: {
    category,
    OpenData,
  },
  mixins: [initWx],
  data() {
    return {
      userId,
      corpId,
      account: {
        auditedNum: {
          route: {
            name: 'mobileDocumentApproval',
            params: {
              index: 3,
            },
          },
          auths: [],
          text: '已审公文数',
          num: 0,
        },
        unReceivedNum: {
          route: {
            name: 'mobileHome',
            query: {
              name: 'home',
            },
          },
          auths: [],
          text: '待收公文数',
          num: 0,
        },
        receivedNum: {
          route: {
            name: 'mobileHome',
            params: {
              index: 1,
            },
            query: {
              name: 'home',
            },
          },
          auths: [],
          text: '已收文数',
          num: 0,
        },
        acceptSendNum: {
          route: {
            name: 'mobileHome',
            query: {
              name: 'home',
            },
          },
          auths: [],
          text: '总收发数',
          num: 0,
        },
      },
    }
  },
  computed: {
    ...mapGetters(['supers', 'commons', 'commissioners', 'auth_code']),
  },
  created() {
    // this.configAuthList()
  },
  mounted() {
    this.getStatisticsNumber()
  },
  methods: {
    getStatisticsNumber() {
      statisticsNumber()
        .then((res) => {
          if (res.code === 200) {
            const keys = Object.keys(res.data)
            keys.forEach((element) => {
              this.account[element].num = res.data[element]
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    toTranslate(i) {
      if (i.auths.includes(this.auth_code)) {
        this.$router.push(i.route)
      }
    },
    getNumZero(num) {
      let n = ''
      let i = 0
      while (i < num) {
        n += 0
        i++
      }
      return n
    },
    formatNum(num) {
      if (num === 0) return '0000'
      if (!num) return ''
      const str = num.toString()
      if (str.length > 3) return num
      return this.getNumZero(4 - str.length) + num
    },
    // 配置权限列表
    configAuthList() {
      const { auditedNum, unReceivedNum, receivedNum, acceptSendNum } = this.account
      auditedNum.auths = [...this.supers, ...this.commons]
      unReceivedNum.auths = [...this.supers, ...this.commons, ...this.commissioners]
      receivedNum.auths = [...this.supers, ...this.commons, ...this.commissioners]
      acceptSendNum.auths = [...this.supers, ...this.commons, ...this.commissioners]
    },
  },
}
</script>
<style lang="less" scoped>
.mobilePerson {
  min-height: calc(100vh - 50px);
  background-color: #f7f7f7;
  .flex {
    display: flex;
    align-items: center;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .header {
    position: relative;
    padding-bottom: 53px;
    margin-bottom: 10px;
    .userAccount {
      position: absolute;
      width: 351px;
      height: 106px;
      background: #ffffff;
      border-radius: 8px;
      justify-content: center;
      left: 50%;
      bottom: 53px;
      transform: translate(-50%, 50%);
      .accountList {
        width: 100%;
        .accountItem {
          height: 48px;
          flex: 1;
          flex-direction: column;
          justify-content: space-between;
          border-left: 1px solid rgba(222, 222, 222, 1);
          &:first-child {
            border: none;
          }
          .label {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
          }
          .value {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #1890ff;
          }
        }
      }
    }
    .headerInfo {
      height: 212px;
      background: linear-gradient(90deg, #0c6bcb 0%, #3b9afa 100%);
      width: 100%;
      .userBaseInfo {
        width: 100%;
        .userInfo {
          padding-left: 18px;
          width: 100%;
          .avat {
            width: 50px;
            height: 50px;
            background-color: rgba(255, 255, 255, 0.4);
            border-radius: 50%;
            position: relative;
            margin-right: 15px;
            .imgs {
              width: 41px;
              height: 41px;
              background: #ffffff;
              border-radius: 50%;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .user {
            .userName {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #fffefe;
              line-height: 1;
              margin-bottom: 8px;
            }
            .part {
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #fffefe;
            }
          }
        }
        .exit {
          width: 92px;
          height: 30px;
          background-color: rgba(255, 255, 255, 0.3);
          border-radius: 15px 0 0 15px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          justify-content: center;
          .imgs {
            width: 15px;
            height: 15px;
            font-size: 0;
            margin-right: 6px;
          }
        }
      }
    }
  }
}
</style>
